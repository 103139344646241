// ESLint reports `fill` is missing, whereas it exists on an SVGProps type
export type SVGProps = React.SVGProps<SVGSVGElement> & {
  fill?: string;
  clickable?: string;
};

export const UniIcon = (props: SVGProps) => (
  <svg
    {...props}
    id="_Шар_1"
    data-name="Шар 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 301.97 95.65"
  >
    <defs>
      <style>
        {`
        .cls-1 {
          fill: #fff;
        }

        .cls-2 {
          fill: #15ab5a;
        }
      `}
      </style>
    </defs>
    <g>
      <path
        className="cls-1"
        d="m92.47,43.95h-8.47l.08-5.83h22.67l-.1,5.83h-8.2l-.37,19.79h-6.06l.44-19.79Z"
      />
      <path
        className="cls-1"
        d="m110.09,42.35c.35-2.82,1.78-4.23,4.3-4.23h18.63l-.3,25.61h-6.06l.1-7.06.15-12.68h-10.29c-.14,0-.27.06-.38.17-.13.11-.19.27-.2.46l-.07,6.19h9.1l-.08,5.89h-9.08l-.08,7.04h-6.02l.29-21.38Z"
      />
      <path
        className="cls-1"
        d="m146.27,55.44h-3.93v-.02s0,.02,0,.02c0,.04,0,.39,0,1.06,0,.76,0,1.67-.02,2.73,0,1.06-.02,2.02-.02,2.89,0,.87,0,1.4-.01,1.58h-6.34l.25-21.36c.35-2.81,1.83-4.21,4.44-4.21h12.42c1.76,0,3.34.66,4.73,1.99,1.39,1.32,2.11,2.84,2.14,4.58-.02,1.49-.04,2.74-.05,3.74,0,1-.02,1.93-.02,2.79-.03,1.23-.46,2.24-1.27,3.04-.82.79-1.89,1.18-3.2,1.18h-2.32l6.85,8.3h-6.78l-6.87-8.3Zm7.75-10.58c-.01-.23-.12-.43-.31-.61-.2-.19-.41-.29-.64-.29h-9.8c-.14,0-.27.06-.4.17-.12.13-.19.28-.2.46l.03,4.9h10.52c.39,0,.61-.18.66-.53l.14-4.1Z"
      />
      <path
        className="cls-1"
        d="m162.76,42.35c.35-2.82,1.78-4.23,4.3-4.23h18.63l-.3,25.61h-6.06l.1-7.06.15-12.68h-10.29c-.14,0-.27.06-.38.17-.13.11-.19.27-.2.46l-.07,6.19h9.1l-.08,5.89h-9.08l-.08,7.04h-6.02l.29-21.38Z"
      />
      <path
        className="cls-1"
        d="m195.52,43.99c-.18,0-.32.05-.42.15-.1.11-.16.24-.18.38,0,.45-.01.83-.01,1.14,0,.3,0,.58,0,.84,0,.26,0,.44,0,.54.02.24.12.45.3.63s.38.27.61.27h9.29c1.71,0,3.23.67,4.57,2.01,1.33,1.33,2,2.86,2.01,4.6,0,.01,0,.03,0,.06,0,.02,0,.26,0,.69,0,.44-.01.95-.02,1.55,0,.6-.02,1.15-.03,1.65-.01.51-.01.84,0,.99-.05,1.25-.48,2.28-1.28,3.08-.81.79-1.87,1.18-3.18,1.18h-18.51c0-1.09,0-2,.02-2.73,0-.73.02-1.4.02-2.01,0-.61,0-1.02,0-1.21h16.27c.35,0,.56-.18.61-.55,0-.46.01-.84.01-1.14,0-.3,0-.59,0-.85,0-.26,0-.44,0-.54.04-.23-.04-.44-.22-.63-.18-.19-.39-.28-.62-.28h.02-9.36c-1.72,0-3.25-.65-4.57-1.96-1.34-1.3-2.01-2.84-2.01-4.61,0-.01,0-.03-.01-.06v-.17c0-.29.02-.78.03-1.46.01-.68.02-1.35.03-2s.01-1.08.02-1.28c.44-2.76,1.92-4.14,4.46-4.14h18.53c0,1.08-.01,1.97-.03,2.69-.01.71-.02,1.38-.03,1.98s0,1.01,0,1.2h-16.29Z"
      />
      <path
        className="cls-1"
        d="m220.34,38.12l4.8,17.66,3.31-11.83-1.71-5.83h6.08l5.14,17.17,3.8-17.17h7.61l-6.43,25.61h-8.77l-2.36-8.18-2.12,8.18h-3.7c-1.42,0-2.75-.48-4-1.44-1.25-.96-2.1-2.18-2.55-3.65l-5.95-20.53h6.85Z"
      />
      <path
        className="cls-1"
        d="m251.75,42.35c.35-2.82,1.78-4.23,4.3-4.23h18.63l-.29,25.61h-6.06l.1-7.06.15-12.68h-10.29c-.14,0-.27.06-.38.17-.13.11-.19.27-.2.46l-.07,6.19h9.1l-.08,5.89h-9.08l-.08,7.04h-6.02l.29-21.38Z"
      />
      <path
        className="cls-1"
        d="m282.39,38.12h12.61c1.8,0,3.4.66,4.81,1.99,1.42,1.32,2.14,2.84,2.16,4.58-.02,1.51-.04,2.76-.05,3.76-.02,1-.03,1.93-.03,2.79-.02,1.23-.44,2.24-1.27,3.04-.83.8-1.9,1.2-3.22,1.2l-13.3-.06s0-.02.01-.02t0,0s0,.43-.01,1.18-.01,1.63-.02,2.64c0,1.08-.01,2.05-.02,2.92s-.01,1.4-.02,1.59h-6.42c.05-4.06.09-7.43.12-10.11.03-2.68.06-5.1.09-7.28.03-2.18.04-3.51.05-3.99.36-2.82,1.86-4.23,4.51-4.23Zm2.08,11.39h10.71c.39,0,.61-.18.66-.53l.12-4.1c-.02-.24-.12-.45-.32-.63-.19-.18-.41-.27-.65-.27h-9.91c-.15,0-.29.06-.42.17-.13.11-.19.27-.2.46v1.54c0,.81,0,1.93.01,3.36Z"
      />
    </g>
    <g>
      <path
        className="cls-2"
        d="m73.22,35.37l-10.97,18.45h-22.54l7.49-13.15h8.56l5.49-9.85H18.89l-6.9,11.02-.08.13-.05-.13L3.14,20.64,36.04,0l-6.36,11.81h28.5c12.01,0,20.7,12.97,15.04,23.56Z"
      />
      <path
        className="cls-2"
        d="m38.06,95.65l6.37-11.81H15.92c-12.01,0-19.68-12.8-14.02-23.39l9.95-18.62h18.17l-10.55,20.43c-.65,1.15.17,2.57,1.5,2.57h34.24l6.89-11.02.08-.13.05.13,8.74,21.2-32.91,20.64Z"
      />
    </g>
  </svg>
);
